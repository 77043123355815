<template>
  <div>
    <div class="login-dialog-wrapper" :style="{
      display: $store.state.loginDisplay ? 'block' : 'none',
    }">
      <div class="login-dialog">
        <!-- 登录/注册 -->
        <div class="login-register-wrapper" v-show="tabIndex == 0 || tabIndex == 1">
          <div class="logo-box">
            <img class="logo" src="@/assets/images/logo.png" />
            <svg class="iconpark-icon-close" id="iconpark-icon-close" @click="$store.commit('setLoginDisplay', false)">
              <use href="#c-close"></use>
            </svg>
          </div>
          <ul class="tab">
            <li :class="{ active: tabIndex == 0 }" @click="tabIndex = 0">
              密码登录
            </li>
            <li :class="{ active: tabIndex == 1 }" @click="tabIndex = 1">
              短信登录
            </li>
          </ul>

          <!-- 密码登录 -->
          <div class="form-wrapper" id="password-login" v-show="tabIndex == 0">
            <div class="form-item">
              <label>账号</label>
              <div>
                <input id="tel" class="input" maxlength="11" v-model="accountNumber" placeholder="请输入手机号" />
              </div>
            </div>
            <div class="form-item">
              <label>密码</label>
              <div class="p-r login-password">
                <input class="input" id="pwd" maxlength="15" :type="pwdPreview ? 'password' : 'text'" v-model="password"
                  placeholder="请输入密码" />
                <svg class="iconpark-icon" v-if="pwdPreview" @click="pwdPreview = false" id="preview-closed">
                  <use href="#preview-close"></use>
                </svg>
                <svg v-else class="iconpark-icon" @click="pwdPreview = true" id="preview-opend">
                  <use href="#preview-open"></use>
                </svg>
              </div>
            </div>
            <div class="forget-password">
              <label class="remember-account cursor-pointer" for="remember-account">
                <input type="checkbox" id="remember-account" />
                <span>记住我的账号</span>
              </label>
              <span class="color-primary cursor-pointer" id="forget-password" @click="tabIndex = 2">
                忘记密码
              </span>
            </div>
            <button class="login is-space" id="loginBtn" @click="pwdLogin">
              登录
            </button>
            <p class="form-desc">
              没有账号？
              <span @click="registerNow" class="color-primary cursor-pointer" id="register-now">
                立即申请免费试用
              </span>
            </p>
          </div>

          <!-- 验证码登录 -->
          <div class="form-wrapper" id="sms-login" v-show="tabIndex == 1">
            <div class="form-item">
              <label>手机号</label>
              <div>
                <input v-model="mobile" id="tel2" class="input" maxlength="11" placeholder="请输入手机号" />
              </div>
            </div>
            <div class="form-item">
              <label>验证码</label>
              <div class="p-r">
                <input id="validateCode" v-model="smsCode" class="input verification-code" type="text"
                  placeholder="请输入验证码" maxlength="6" />
                <p class="send-code">
                  <input type="button" @click="sentCode" :value="msgText" id="send-code" />
                </p>
              </div>
            </div>
            <p class="error-text" id="error-code" style="display: none">
              <svg class="iconpark-icon-error">
                <use href="#Frame"></use>
              </svg>
            </p>
            <div style="height: 26px"></div>
            <button class="login" id="loginBtn2" @click="codeLogin">
              登录/注册
            </button>
            <p class="form-desc">未注册手机号验证后将自动登录</p>
          </div>

          <div class="agreement-policy">
            <p>
              登录即表示同意<a class="link" target="_blank" href="#/UserAgreement">用户协议</a>和<a class="link" target="_blank"
                href="#/PrivacyAgreement">个人信息保护政策</a>
            </p>
          </div>
        </div>

        <!-- 忘记密码 -->
        <div class="forget-password-wrapper" v-show="tabIndex == 2">
          <div>
            <span class="back-login" id="back-login" @click="tabIndex = 0">
              <svg class="iconpark-icon">
                <use href="#c-left"></use>
              </svg>
              返回登录
            </span>
          </div>
          <ul class="tab">
            <li class="active">找回密码</li>
          </ul>
          <div class="form-wrapper" id="retrieve-password">
            <div class="form-item">
              <label>手机号</label>
              <div>
                <input v-model="forgetMobile" id="tel3" class="input" maxlength="11" placeholder="请输入手机号" />
              </div>
            </div>
            <div class="form-item">
              <label>验证码</label>
              <div class="p-r">
                <input v-model="forgetCode" class="input verification-code" type="text" id="validateCode2"
                  placeholder="请输入验证码" maxlength="6" />
                <p class="send-code">
                  <input type="button" @click="forgetSentCode" :value="forgetMsgText" />
                </p>
              </div>
            </div>
            <div class="form-item">
              <label>设置新密码</label>
              <div>
                <input class="input" type="password" id="newPassword" v-model="resetPassword" maxlength="15" show-password
                  placeholder="请输入6-15位新密码" />
              </div>
            </div>
            <p class="error-text" id="error-retrieve-password" style="display: none">
              <svg class="iconpark-icon-error">
                <use href="#Frame"></use>
              </svg>
            </p>
            <button class="login" id="change-password-btn" @click="forgetPwdLogin">
              确认登录
            </button>
          </div>
          <div class="agreement-policy">
            <p>
              登录即表示同意<a class="link" target="_blank" href="#/UserAgreement">用户协议</a>和<a class="link" target="_blank"
                href="#/PrivacyAgreement">个人信息保护政策</a>
            </p>
          </div>
        </div>

        <!-- 申请试用 -->
        <div class="forget-password-wrapper" v-show="tabIndex == 3">
          <div class="logo-box">
            <img class="logo" src="@/assets/images/logo.png" />
            <svg class="iconpark-icon-close" id="iconpark-icon-close" @click="$store.commit('setLoginDisplay', false)">
              <use href="#c-close"></use>
            </svg>
          </div>
          <div style="text-align: center;">
            <h5 style="font-size:20px;font-weight: 1000;color: rgb(51, 51, 51);padding-top: 20px;">免费试用</h5>
            <div>留下你的联系方式，立享产品试用及服务</div>
          </div>
          <div class="form-wrapper" id="retrieve-password">
            <div class="form-item">
              <div>
                <input v-model="name" id="tel3" class="input" placeholder="称呼" />
              </div>
            </div>
            <div class="form-item">
              <div class="p-r">
                <input v-model="mobile" class="input" placeholder="联系方式" />
                <div style="font-size: 13px;color: #f03737;" v-show="!isValidMobile">手机号码格式不正确</div>
              </div>
            </div>
            <div class="form-item">
              <div>
                <input v-model="company_name" class="input" placeholder="企业名称" />
              </div>
            </div>
            <div class="form-item">
              <div>
                <input v-model="email" class="input" placeholder="邮箱" />
                <div style="font-size: 13px;color: #f03737;" v-show="!isValidEmail">邮箱格式不正确</div>
              </div>
            </div>
            <p class="error-text" id="error-retrieve-password" style="display: none">
              <svg class="iconpark-icon-error">
                <use href="#Frame"></use>
              </svg>
            </p>
            <button class="login" @click="submitOnTrial">
              提交
            </button>
            <p class="form-desc">
              已有账号
              <span @click="tabIndex = 0" class="color-primary cursor-pointer" id="register-now">
                立即登录
              </span>
            </p>
          </div>
        </div>

        <!-- 设置新密码成功 -->
        <div class="set-password-success-wrapper" style="display: none">
          <div>
            <svg class="iconpark-icon-success">
              <use href="#c-success"></use>
            </svg>
            <p class="success-tips">设置新密码成功</p>
            <p class="success-desc"><span id="countdown"></span>s 后进入元空AI</p>
          </div>
          <button class="login" onclick="location.href='https://yuankong-ai.com'">
            开始使用
          </button>
        </div>
      </div>
    </div>
    <el-dialog class="loginResultDialog" :modal="status" :visible.sync="resultDialogVisible" width="30%"
      :before-close="handleClose">
      <div style="display: flex;flex-wrap: wrap;justify-content: center;text-align: center;" v-if="status">
        <img src="../../assets/images/success.png" alt="" style="width: 94px;height: 71px;">
        <h3 style="font-size: 20px;font-weight:650;width: 100%;">
          提交成功
        </h3>
        <div style="line-height: 30px;font-size: 13px;width: 100%;">
          已收到您提交的信息，客服人员将在1个工作日内与您取得联系<br />
          添加企业微信，为您优先提供1v1解答
        </div>
        <img src="../../assets/images/weChatCode.jpg" style="width: 128px;margin-top: 20px;" alt="">
      </div>
      <div style="display: flex;flex-wrap: wrap;justify-content: center;text-align: center;" v-if="!status">
        <img src="../../assets/images/errorResult.png" alt="" style="width: 62px;height: 57px;">
        <h3 style="font-size: 20px;font-weight:650;width: 100%;">
          该账号还未开通
        </h3>
        <div style="line-height: 30px;font-size: 13px;width: 100%;">
          留下您的联系方式，立享产品试用及服务<br />
          添加企业微信，为您优先提供1v1解答
        </div>
        <img src="../../assets/images/weChatCode.jpg" style="width: 128px;margin-top: 20px;" alt="">
      </div>
    </el-dialog>
  </div>
</template>
<script>
const MSGINIT = "发送验证码";
const MSGSCUCCESS = "${time}秒后重发";
const FORGETMSGSCUCCESS = "${time}秒后重发";
const MSGTIME = 30;

import { userSendMsg, userLogin, resetPassWord, applyForProbation } from "@/api/user.js";
import { getCookie } from "@/utils/cookie";
import { checkModbile, validateEmail } from '@/utils/utils'
export default {
  name: "loginDIalog",
  data() {
    return {
      status: false,
      resultDialogVisible: false,
      tabIndex: 0,
      mobile: "",
      pwdPreview: true,
      accountNumber: "",
      password: "",
      msgText: MSGINIT,
      msgKey: false,
      msgTime: 30,
      forgetMsgText: MSGINIT,
      forgetMsgKey: false,
      forgetMsgTime: 30,
      resetPassword: "",
      smsCode: "",
      forgetMobile: "",
      forgetCode: "",
      name: '',
      company_name: '',
      email: '',
      isValidMobile: true,
      isValidEmail: true
    };
  },
  props: {
    display: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleClose() {
      this.resultDialogVisible = false
    },
    submitOnTrial() {
      this.isValidMobile = checkModbile(this.mobile)
      if (!this.isValidMobile) return
      this.isValidEmail = validateEmail(this.email)
      if (!this.isValidEmail) return

      applyForProbation({
        access_token: getCookie('access_token'),
        name: this.name,
        mobile: this.mobile,
        company_name: this.company_name,
        email: this.email,
      }).then(res => {
        this.resultDialogVisible = true
        this.status = true
        this.$store.commit("setLoginDisplay", false);
        this.$store.commit("setloginToken", res.data.data.login_token);
        this.$store.commit("setMobile", this.mobile);
      })
    },
    forgetPwdLogin() {
      resetPassWord({
        mobile: this.forgetMobile,
        sms_code: this.forgetCode,
        password: this.resetPassword,
        access_token: getCookie("access_token"),
      }).then((res) => {
        console.log(res);
      });
    },
    registerNow() {
      this.tabIndex = 3;
    },
    codeLogin() {
      userLogin({
        mobile: this.mobile,
        sms_code: this.smsCode,
        access_token: getCookie("access_token"),
      }).then((res) => {
        this.$store.commit("setLoginDisplay", false);
        this.$store.commit("setloginToken", res.data.data.login_token);
        this.$store.commit("setMobile", this.mobile);
        this.$message.success("登录成功！");
      }).catch(err => {
        this.resultDialogVisible = true
        this.$store.commit("setLoginDisplay", false);
        this.status = false
      })
    },
    pwdLogin() {
      userLogin({
        mobile: this.accountNumber,
        password: this.password,
        access_token: getCookie("access_token"),
      }).then((res) => {
        console.log(res,6666)
        this.$store.commit("setLoginDisplay", false);
        this.$store.commit("setloginToken", res.data.data.login_token);
        this.$store.commit("setMobile", this.accountNumber);
        this.$message.success("登录成功！");
      }).catch(err => {
        this.resultDialogVisible = true
        this.$store.commit("setLoginDisplay", false);
        this.status = false
      })
    },
    sentCode() {
      if (this.msgKey) return;
      this.timeCacl();
      userSendMsg({
        mobile: this.mobile,
        access_token: getCookie("access_token"),
      }).then((res) => {
        // 发送验证码
      });
    },
    timeCacl() {
      // 计时避免重复发送
      this.msgText = MSGSCUCCESS.replace("${time}", this.msgTime);
      this.msgKey = true;
      const time = setInterval(() => {
        this.msgTime--;
        this.msgText = MSGSCUCCESS.replace("${time}", this.msgTime);
        if (this.msgTime === 0) {
          this.msgTime = MSGTIME;
          this.msgText = MSGINIT;
          this.msgKey = false;
          clearInterval(time);
        }
      }, 1000);
    },
    forgetSentCode() {
      if (this.forgetMsgKey) return;
      this.forgetTimeCacl();

      userSendMsg({
        mobile: this.forgetMobile,
        access_token: getCookie("access_token"),
      }).then((res) => {
        // 发送验证码
      });
    },
    forgetTimeCacl() {
      // 计时避免重复发送
      this.forgetMsgText = FORGETMSGSCUCCESS.replace(
        "${time}",
        this.forgetMsgTime
      );
      this.forgetMsgKey = true;
      const time = setInterval(() => {
        this.forgetMsgTime--;
        this.forgetMsgText = FORGETMSGSCUCCESS.replace(
          "${time}",
          this.forgetMsgTime
        );
        if (this.forgetMsgTime === 0) {
          this.forgetMsgTime = MSGTIME;
          this.forgetMsgText = MSGINIT;
          this.forgetMsgKey = false;
          clearInterval(time);
        }
      }, 1000);
    },
  },
};
</script>

<style>
.loginResultDialog {
  .el-dialog__header {
    padding: 0;
  }

  .el-dialog {
    border-radius: 25px;
    overflow: hidden;
  }

}
</style>